import {Stack} from "@mui/material";
import {
	authorizedByLogin,
	generalError,
	SbPageHeader,
	SbSettings, userSessionProvider
} from "@surebase/shared-component-library";
import React from "react";
import {ErrorBoundary} from "react-error-boundary";

export const SettingsPage = authorizedByLogin(() => {
	const user = userSessionProvider.userSession?.user;
	const accessToken = userSessionProvider.userSession?.accessToken;
	
	return <Stack gap={3} >
		<ErrorBoundary FallbackComponent={generalError}>
			<SbPageHeader pageName={"Settings"} overviewRoute={"#"} disableBreadcrumbs={true}/>
			<SbSettings user={user} accessToken={accessToken} isCustomerPortal={true}/>
		</ErrorBoundary>
	</Stack>;
});
